'use strict';

module.exports = {  
    categorylist: [
      { id: 1, name: "Games", color: "#3498db" },
      { id: 2, name: "Video", color: "#1abc9c" },
      { id: 3, name: "Media", color: "#9b59b6" },
      { id: 4, name: "Sports", color: "#f39c12" },
      { id: 5, name: "Mixed", color: "#27ae60" },
      { id: 6, name: "e-Learning", color: "#34495e" },
      { id: 7, name: "Other", color: "#c0392b" }
    ],
      adTypeArray: [
        {id: 1, name: "Banner"},
        {id: 2, name: "APK"},
      ],
      channelTypeArray: [
        {id: 1, name: "Browsing"},
        {id: 2, name: "Auto"},
      ],
      networkTypeArray: [
        {id: 1, name: "wifi"},
        {id: 2, name: "op2"},
        {id: 3, name: "op3"},
        {id: 4, name: "op4"},
      ],
      getLandingImages : process.env.VUE_APP_GETIMAGEURL + '/landing',
      getBannerImages : process.env.VUE_APP_GETIMAGEURL + '/banner',
      getAddImages : process.env.VUE_APP_GETIMAGEURL + '/ad',
      exportTrnx : process.env.VUE_APP_BASEURL + '/transcation/export?trxid=',
      publicexportTrnx : process.env.VUE_APP_PUBLIC_BASEURL + 'public/transcation/export?trxid=',
      publicgetLandingImages : process.env.VUE_APP_PUBLIC_BASEURL + 'public/image/landing',
      publicgetBannerImages : process.env.VUE_APP_PUBLIC_BASEURL + 'public/image/banner',
      publicgetAddImages : process.env.VUE_APP_PUBLIC_BASEURL + 'public/image/ad',
      
      downloadCSV(data) {
        const rows = [];
        const headers = Object.keys(data[0]);
      
        // Add headers to CSV
        rows.push(headers.join(','));
      
        // Add data to CSV
        data.forEach((item) => {
          const values = headers.map((header) => {
            const value = item[header];
            return JSON.stringify(value);
          });
          rows.push(values.join(','));
        });
      
        // Convert to CSV and download
        const csv = rows.join('\n');
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'ExampleBulkImportFile.csv';
        link.click();
      },
      sentenceCase(str) {
        if (typeof str !== 'string') return str
        return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
      },
      isValidUrl(str){
        try {
          new URL(str);
          return true;
        } catch (error) {
          return false;
        }
      },
      CopyShareUrl(urls){
        var text = urls;
        if(Array.isArray(urls)){
         text = urls.join('\n');
        }
        var el = document.createElement('textarea');
        el.addEventListener('focusin', e => e.stopPropagation());
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    },
    checkCountry(data){
      let user = JSON.parse(localStorage.getItem('userinfo'))
      if(user.role != 'manager' && localStorage.getItem('country')){
        data.country = localStorage.getItem('country')
      }
      return data
    },
    validatePort(port) {
      const portRegex = /^(0|[1-9]\d{0,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
      return portRegex.test(port);
    },
    validateHost(host) {
      const hostRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return hostRegex.test(host);
    }    
}